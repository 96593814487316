<template>
  <div class="relative">
    <h2
      class="mb-[20px] font-roboto text-[32px] font-medium leading-[38px] text-[#1f1f1f]"
    >
      Популярное
    </h2>

    <ClientOnly>
      <Swiper
        :breakpoints="breakpoints"
        :slides-per-view="1"
        :space-between="20"
        @swiper="onSwiper"
      >
        <SwiperSlide v-for="(item, index) in articles" :key="item.id || index">
          <MainPageCardsReworkedBaseCard
            style="height: auto"
            :data="item"
            :title-length="85"
            disable-announce
        /></SwiperSlide>
      </Swiper>
    </ClientOnly>
    <SlidersSliderControl
      type="prev"
      class="absolute left-[-16px] top-[55%] z-10 hidden md:block"
      :class="isBeginning ? 'opacity-50' : 'cursor-pointer'"
      @click="slidePrev()"
    />
    <SlidersSliderControl
      type="next"
      class="absolute right-[-16px] top-[55%] z-10 hidden md:block"
      :class="isEnd ? 'opacity-50' : 'cursor-pointer'"
      @click="slideNext()"
    />
  </div>
</template>

<script setup>
import 'swiper/css';

import { Swiper, SwiperSlide } from 'swiper/vue';

const { onSwiper, slideNext, slidePrev, isEnd, isBeginning } = useSwiper();
const breakpoints = {
  1024: {
    slidesPerView: 3,
  },
  1280: {
    slidesPerView: 4,
  },
};

const { popularArticles } = usePopularArticles({ options: { staleTime: 0 } });
const articles = computed(() => popularArticles?.data?.value);
</script>

<style lang="scss" scoped>
.swiper-button-prev,
.swiper-button-next {
  @apply hidden lg:block;
}
</style>
